import React from "react"

const PhotoGallery = (props) => {

  const { post, blockTitle } = props

  const images = post?.relationships?.field_gallery
  if(!images){
    return
  }
  const getTitle = (image) => {
    return post?.field_gallery[image]?.alt || post?.field_gallery[image]?.title
  }

  return(
    <div className="offset-lg-1 col-md-12 col-lg-10 p-0 photo-gallery m-b-40">
      <div className="row small-0">
      {images && images.map((image, index) => {
        return(
          <div className="col-lg-3 col-md-6 col-12 small-m-b-32 medium-m-b-40" key={index}>
            <img
              src={image.image_style_uri._653_432} alt={getTitle(index)}
              className="m-b-8"
             />
            <div className="gallery-title">{getTitle(index)}</div>
          </div>
        )
      })}
      </div>
    </div>
  )
}

export default PhotoGallery
