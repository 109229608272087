import React, {useContext, useState} from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Description from "../components/section/section-description"
import { LanguageContext } from "../context"
import InteriorContactItem from "../components/interior-contact-item"
import Banner from "../components/banner"
import Video from "../components/video"
import ReactPlayer from 'react-player/youtube'
import Sectionfullwithimage from '../components/section/section-full-with-image'
import Button2 from "../components/button-generic"
import ButtonPrayer from "../components/buttons/button-cta-top-content"
import ComplexContent from "../components/section/complex-content"
import SimpleContent from "../components/section/simple-content"

const PageSubministry = ({data, location, pageContext}) => {

    const post = data.nodeSubministry
    const { t } = useContext(LanguageContext)
    const translationPaths = {
     en: `/en${post?.path?.alias}`,
     es: `/es${post?.path?.alias}`,
     ca: `${post?.path?.alias}`
   }

   //const [isComplex, setIsComplex] = useState(post?.node?.field_show_on_here_for_you)


    return (
        <div className="page-interior">
          <Layout
            translationPaths={translationPaths}
            location={location}
            langcode={post?.langcode}
            >
            <Seo
                title={post?.title}
                lang={post?.langcode}
                description={post?.body?.summary}
                image={post?.relationships?.field_image?.image_style_uri?._653_432}
              />
            <Header
              lang={pageContext?.langcode}
              location={location}
             />


             {
              post?.field_design_type === 'complex'

              ?

              <ComplexContent
                post={post}
                t={t}
               />

              :

              <SimpleContent
                post={post}
                t={t}
               />

              }

              <Footer
              lang={pageContext?.langcode}
              location={location}
             />
          </Layout>
        </div>
    )
}

export const pageQuery = graphql`
query PageSubministry ($drupal_internal__nid: Int, $langcode: String!){
    nodeSubministry(drupal_internal__nid: {eq: $drupal_internal__nid}, langcode:{eq: $langcode}){
          id
          drupal_internal__nid
          title
          field_show_on_here_for_you
          field_cta {
            title
            uri
            url
          }
          field_video_gallery {
            title
            uri
          }
          field_design_type
          relationships {
            field_resource {
              title
              field_cta {
                title
                uri
                url
              }
              body {
                summary
                value
              }
              relationships {
                field_image {
                  image_style_uri {
                    _653_432
                  }
                }
              }
            }
            field_block_2_image {
                image_style_uri {
                  _653_432
                }
              }
              field_block_1_image {
                image_style_uri {
                  _653_432
                }
              }
             field_gallery {
               image_style_uri {
                 _653_432
                 _1103x488
                 _120x120
                 _1440x408
                 _1440x960
                 _294x192
                 _541x359
                 large
               }
              }
              field_ministry {
                title
              }
              field_image {
                image_style_uri {
                  _653_432
                  _1103x488
                  _120x120
                  _1440x408
                  _1440x960
                  _294x192
                  _541x359
                  wide
                }
                uri {
                  url
                }
              }
            }
            field_gallery {
            title
            alt
          }
          field_image {
            alt
            title
          }
          body {
            processed
            summary
            value
          }
          field_weight
          field_block_1_body {
            value
          }
          field_block_1_cta {
            title
            url
            uri
          }
          field_block_1_title
          field_block_2_body {
            value
          }
          field_block_2_cta {
            title
            uri
            url
          }
          field_block_2_title
          field_form {
            drupal_internal__target_id
          }
        }
    }
`
export default PageSubministry
