import React, { Component, useContext, useMemo } from "react"
import * as JsSearch from "js-search"
import BlogResource from "../blog/blog-item"
import {Link} from "gatsby"
import Utils from "../../utils"
import Icon from "../../../src/components/icon"
import Pagination from "../pagination/index2"
import memoizeOne from 'memoize-one'
import '../../assets/css/pagination.scss';

const Resource = (props) => {

  const {
    title,
    type,
    description,
    path,
    readmore,
    icon,
    className,
    imageUrl,
    documentUrl
  } = props

  return(
    <div className={className}>
    {imageUrl &&
      <div className="result-image">
        <img src={imageUrl} alt={title} title={title} />
      </div>
    }


    <div className="result-text">
      <div className="result-title">{title}</div>
      <div className="result-desc m-b-16">
        <div dangerouslySetInnerHTML={{__html: description}} />
      </div>
      <div className="result-read-more">
        <a className="r-more p-l-16-" href={documentUrl ? documentUrl : path} >
          {readmore}
          {icon &&
            <Icon
              icon={icon}
              className="icon"
              size={14}
            />
          }
        </a>
      </div>

      </div>

    </div>
  )
}

export default Resource;
