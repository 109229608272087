import React, {useContext, useState} from "react"
import { graphql, navigate } from "gatsby"
import Banner from "../banner"
import Video from "../video"
import Sectionfullwithimage from './section-full-with-image'
import ButtonPrayer from "../buttons/button-cta-top-content"
import Gallery from "./photo-gallery"
import VideoGallery from "./video-gallery"
import Sidebar from "./sidebar"
import Item from "../item"
import Utils from "../../utils"

const ComplexContent = ( props ) => {

  const { post, t } = props

  return (
    <div className="page-subministry">
    <Banner
      post={post}
      height={"480px"}
      buttonLinkBanner={post?.field_cta?.title}
      hasButton={true}
      summaryAsBody={true}
      backgroundColor="green"
    />
      <section className="interior-content m-t-64 m-b-80 small-m-t-40 small-m-b-40 medium-m-b-24">
       <div className="container">
        <div className="row small-m-0">
          <div className="col-12 col-md-12 col-lg-6 p-0 m-r-20 offset-md-1 small-m-r-0 small-p-l-r-5 medium-m-l-0">
            <div
              dangerouslySetInnerHTML={{__html: post?.body?.processed}}
              className="simple-page p-b-80 small-p-b-40 subministry"
            />

            <div className="resources-content">
            {post?.relationships.field_resource && post?.relationships?.field_resource?.length > 0 &&
              post?.relationships.field_resource.map((item, key) => {
                return(
                  <Item
                    key={key}
                    className="col-12 col-md-6 col-lg-4 m-b-34"
                    title={item?.title}
                    type={Utils.getType(item?.type)}
                    description={Utils.getDescription(item)}
                    labelCta="Veure més"
                    path={item?.field_cta?.url.replace("ca/", "")}
                    readmore={item.type === 'node--blog_post' ? "Llegir-ho" : "Veure més"}
                    icon={item.type === 'node--blog_post' ? "icons-32-x-32-generic-plus" : ""}
                    imageUrl={item?.relationships?.field_image?.image_style_uri?._653_432}
                  />
                )
              })
            }
            </div>

          </div>
            <Sidebar
              post={post}
            />
        </div>
      </div>

      {post?.field_block_1_title &&
        <Sectionfullwithimage
          post={post}
          title={post?.field_block_1_title}
          imagePos="left"
          color="bordeaux"
          body={post?.field_block_1_body?.value}
          image={post?.relationships?.field_block_1_image?.image_style_uri?._653_432}
          path={post?.path?.alias}
          button={post?.field_form && post?.field_form?.drupal_internal__target_id ?
            <ButtonPrayer
                    key={post?.field_block_1_title}
                    title={post?.field_block_1_cta?.title}
                    backgroundimage={post?.relationships?.field_block_1_image?.image_style_uri?._653_432}
                    formId={post?.field_form?.drupal_internal__target_id}
                    className={` btn btn-default btn-outline-visit-us bordeaux ` }
         /> : ""}

        />
      }

    {post?.field_block_2_title &&
      <Sectionfullwithimage
        post={post}
        title={post?.field_block_2_title}
        imagePos="right"
        color="green-dark"
        body={post?.field_block_2_body?.value}
        image={post?.relationships?.field_block_2_image?.image_style_uri?._653_432}
        path={post?.path?.alias}
        button={post?.field_block_2_cta && post?.field_block_2_cta?.title === "Trucar Equip Pastoral" ?
          <a
            href={post?.field_block_2_cta?.uri}
            className={`btn btn-default btn-outline-visit-us green-dark`}
            >
            {post?.field_block_2_cta?.title}
          </a>
           : ""}
      />
    }


      <div className="container">
        <div className="row small-0">
          {post?.relationships?.field_gallery && post?.relationships?.field_gallery?.length > 0 &&
          <Gallery
            post={post}
           />
         }
          {post?.field_video_gallery && post?.field_video_gallery?.length > 0 &&
           <VideoGallery
             post={post}
            />
          }
        </div>
      </div>


      </section>
      </div>
  )
}

export default ComplexContent
