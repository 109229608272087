import React, { useState } from "react";

const Video = ({ videoSrcURL, videoTitle, thumbnail, ...props }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const handleThumbnailClick = () => {
    setVideoPlaying(true);
  };

  return (
    <div className="video">
      {thumbnail && !videoPlaying && (
        <img
          src={thumbnail}
          alt="Thumbnail"
          style={{ width: "100%", height: "auto", cursor: "pointer" }}
          onClick={handleThumbnailClick}
        />
      )}
      {(videoPlaying || !thumbnail) && (
        <iframe
          src={`${videoSrcURL}${thumbnail && videoPlaying ? "?autoplay=1" : ""}`}
          title={videoTitle}
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default Video;
