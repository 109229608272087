import React, { useContext } from "react"
import { LanguageContext } from "../../context"

const SectionDescription = (props) => {
  const {title} = props
  const { t } = useContext(LanguageContext)
  return(
    <div className="section-description p-b-72 small-p-b-40 medium-p-b-24">
      {title}
      <p className="cta m-t-20">{t("Segueix llegint per saber més sobre aquest ministeri!")}</p>
    </div>
  )
}

export default SectionDescription
